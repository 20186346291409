/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Container, Flex } from "@theme-ui/components"
import { InlineVis } from "./InlineVis"
import { FoundationBox, StaticFoundationBox } from "../FoundationBox"
import { Stack } from "raam"
import { useAfterScrollPoint } from "../../services"
import { motion } from "framer-motion"

export const Foundations = () => {
    return (
        <InlineVis id="foundation-blocks">
            <FoundationsInner />
        </InlineVis>
    )
}

const FoundationsInner = () => {
    const inFocus = useAfterScrollPoint("foundation-blocks")
    const variant = inFocus ? "focus" : "hidden"

    return (
        <motion.div animate={variant}>
            <Container>
                <Stack gap="m" alignItems="center">
                    <Flex
                        sx={{
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                        }}
                    >
                        <FoundationBox>privacy</FoundationBox>
                        <FoundationBox>consent</FoundationBox>
                        <FoundationBox>partnership</FoundationBox>
                        <FoundationBox>accountability</FoundationBox>
                        <FoundationBox>transparency</FoundationBox>
                    </Flex>
                </Stack>
            </Container>
        </motion.div>
    )
}

export const StaticFoundations = () => {
    return (
        <Container>
            <Stack gap="m" alignItems="center">
                <Flex
                    sx={{
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                    }}
                >
                    <StaticFoundationBox>privacy</StaticFoundationBox>
                    <StaticFoundationBox>consent</StaticFoundationBox>
                    <StaticFoundationBox>partnership</StaticFoundationBox>
                    <StaticFoundationBox>accountability</StaticFoundationBox>
                    <StaticFoundationBox>transparency</StaticFoundationBox>
                </Flex>
            </Stack>
        </Container>
    )
}
