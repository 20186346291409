/** @jsx jsx */
import { jsx } from "theme-ui"
import { Container } from "@theme-ui/components"
// @ts-ignore
import source from "../../images/green.gif"

import LazyLoad from "react-lazyload"

const Personal = () => {
    return (
        <Container px={["s", "m", "l"]}>
            <LazyLoad once height={340} offset={500}>
                <img
                    src={source}
                    sx={{ width: "100%", maxWidth: "100%" }}
                    alt="Conceptual animaiton for consent driven personal data."
                />
            </LazyLoad>
        </Container>
    )
}

export default Personal
