/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Container, Text } from "@theme-ui/components"
import { useMeasure } from "../services/useMeasure"

import { dataA, dataB, dataC, ModelRow, dataPre } from "../data/modellingData"
import { scaleLinear, line as d3line, range as d3range } from "d3"
import { Fragment } from "react"
import { motion } from "framer-motion"
import { useAfterScrollPoint } from "../services"

function Vis({ width, height }: { width: number; height: number }) {
    const x = scaleLinear().domain([0, 360]).range([0, width])
    const y = scaleLinear().domain([0, 10000]).range([height, 0])
    const line = d3line<ModelRow>()
        .x(d => x(d[""]))
        .y(d => y(d["Number infected"]))
    const yTicks = [10000, 8000, 6000, 4000, 2000]

    const show = useAfterScrollPoint("modelling")

    return (
        <Fragment>
            <defs>
                <clipPath id="fullclip">
                    <motion.rect
                        animate={{ width: show ? width + 100 : 0 }}
                        transition={{ duration: 3 }}
                        x={-100}
                        y={-20}
                        height={height + 120}
                        width={0}
                    />
                </clipPath>
            </defs>
            <g clipPath="url(#fullclip)">
                <rect
                    x={x(60)}
                    y={0}
                    height={height}
                    width={x(150) - x(60)}
                    fill="#3353ff"
                    fillOpacity={0.05}
                />

                <text
                    sx={{
                        variant: "text.subheadline",
                        letterSpacing: 0.5,
                        fontSize: ["s", "m"],
                    }}
                    x={x(105)}
                    y={y(9000)}
                    textAnchor="middle"
                >
                    90 Day Lockdown
                </text>

                <text
                    sx={{ variant: "text.body-s", fontSize: ["xs", "s"] }}
                    x={x(220)}
                    y={y(8000)}
                    fill="red"
                >
                    No measures
                </text>

                <text
                    sx={{ variant: "text.body-s", fontSize: ["xs", "s"] }}
                    x={x(310)}
                    y={y(4500)}
                    textAnchor="middle"
                    fill="darkorange"
                >
                    Some measures
                </text>

                <text
                    sx={{ variant: "text.body-s", fontSize: ["xs", "s"] }}
                    x={x(310)}
                    y={y(500)}
                    textAnchor="middle"
                    fill="green"
                >
                    Smart measures
                </text>

                <path
                    d={line(dataPre)}
                    stroke="black"
                    fill="none"
                    strokeWidth="3"
                />

                <path
                    d={line(dataA)}
                    stroke="green"
                    fill="none"
                    strokeWidth="2"
                />

                <path
                    d={line(dataB)}
                    stroke="red"
                    fill="none"
                    strokeWidth="2"
                />

                <path
                    d={line(dataC)}
                    stroke="darkorange"
                    fill="none"
                    strokeWidth="2"
                />

                <line
                    x1={0}
                    x2={width}
                    y1={height - 1}
                    y2={height - 1}
                    strokeWidth="1"
                    stroke="black"
                ></line>

                <line
                    x1={1}
                    x2={1}
                    y1={0}
                    y2={height - 1}
                    strokeWidth="1"
                    stroke="black"
                ></line>

                <line
                    x1={x(60)}
                    x2={x(60)}
                    y1={0}
                    y2={height - 1}
                    stroke="black"
                    strokeDasharray="4 4"
                />

                <line
                    x1={x(150)}
                    x2={x(150)}
                    y1={0}
                    y2={height - 1}
                    stroke="black"
                    strokeDasharray="4 4"
                />

                {yTicks.map((n, i) => (
                    <g key={i}>
                        <line
                            x1={1}
                            x2={4}
                            y1={y(n)}
                            y2={y(n)}
                            stroke="black"
                        ></line>
                        <text
                            sx={{ variant: "text.normal", fontSize: "xs" }}
                            x={6}
                            y={y(n) - 1}
                        >
                            {n}
                        </text>
                    </g>
                ))}

                {d3range(361)
                    .filter((_, i) => i % 5 === 0)
                    .map((_, i) => i * 5)
                    .map(i => (
                        <g key={i}>
                            <line
                                x1={x(i)}
                                x2={x(i)}
                                y1={height - 1}
                                y2={height + 4}
                                stroke="black"
                            ></line>
                            {i === 60 && (
                                <text
                                    sx={{
                                        variant: "text.normal",
                                        fontSize: "s",
                                    }}
                                    x={x(60)}
                                    y={height + 10}
                                    textAnchor="middle"
                                >
                                    60
                                </text>
                            )}
                            {i === 150 && (
                                <text
                                    sx={{
                                        variant: "text.normal",
                                        fontSize: "s",
                                    }}
                                    x={x(150)}
                                    y={height + 10}
                                    textAnchor="middle"
                                >
                                    150
                                </text>
                            )}
                        </g>
                    ))}

                <text
                    sx={{
                        variant: "text.headline",
                        fontSize: "xs",
                        letterSpacing: 1,
                    }}
                    x={0}
                    y={-10}
                >
                    Active Cases
                </text>

                <text
                    sx={{
                        variant: "text.headline",
                        fontSize: "xs",
                        letterSpacing: 1,
                    }}
                    x={width / 2}
                    y={height + 20}
                    textAnchor="middle"
                >
                    Days
                </text>
            </g>
        </Fragment>
    )
}

export const Modelling = () => {
    const [ref, bounds] = useMeasure()

    return (
        <Container variant="invline-vis" px={["s", "m"]}>
            <Text
                variant="headline"
                sx={{ fontSize: ["xs", "s"], textAlign: "center" }}
                pb="m"
            >
                Projected Active Cases
            </Text>
            <Box ref={ref} sx={{ paddingTop: "52%", position: "relative" }}>
                <svg
                    sx={{
                        variant: "position.inset0",
                        overflow: "visible",
                    }}
                    viewBox={`0 0 ${bounds.width} ${bounds.height}`}
                >
                    <Vis width={bounds.width} height={bounds.height} />
                </svg>
            </Box>
        </Container>
    )
}
