/** @jsx jsx */
import { jsx } from "theme-ui"
import { Container } from "@theme-ui/components"
import { Fragment } from "react"
import BlockContent from "@sanity/block-content-to-react"
import {
    BlockRenderer,
    LinkRenderer,
    ListItemRenderer,
    StaticTransitionCalloutRenderer,
    StoryContainer,
    StaticVisualisationRenderer,
} from "./renderers"
import { Stack } from "raam"

export const ListRenderer = props => {
    return (
        <Container px={props.level === 1 ? ["s", "m", "l"] : 0}>
            <Stack pl="m" gap="m">
                {props.children}
            </Stack>
        </Container>
    )
}

const serializers = {
    types: {
        block: BlockRenderer,
        transitionCallout: StaticTransitionCalloutRenderer,
        visualisation: StaticVisualisationRenderer,
    },
    marks: {
        link: LinkRenderer,
    },
    container: StoryContainer,
    list: ListRenderer,
    listItem: ListItemRenderer,
}

export const StaticStoryRenderer = ({ blocks }) => {
    return <BlockContent blocks={blocks} serializers={serializers} />
}
