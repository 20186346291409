/** @jsx jsx */
import { jsx } from "theme-ui"
import { Text, Container, Box } from "@theme-ui/components"
import { Stack } from "raam"
import { useVariableScrollRange, useSetScrollPointWithChild } from "../services"
import { useTransform, motion } from "framer-motion"
import { Stack as MyStack } from "../components/Stack"

type Props = {
    id: string
    height: string
    title: string
    text: string
}

export const TransitionCallout: React.FC<Props> = props => {
    const [ref, childRef] = useSetScrollPointWithChild(
        `${props.id}-start`,
        `${props.id}-end`
    )

    return (
        <Box ref={ref}>
            <Box ref={childRef} sx={{ position: "sticky", top: "60vh" }}>
                <TransitionCalloutInner {...props} />
            </Box>
            <Box sx={{ height: props.height }}></Box>
        </Box>
    )
}

const TransitionCalloutInner: React.FC<Props> = props => {
    return (
        <Container px={["s", "m", "l"]}>
            <Box
                pl="l"
                p="m"
                bg="white"
                sx={{
                    position: "relative",
                    boxShadow: ".5rem .5rem 0 lightgray",
                }}
            >
                <Indicator id={props.id} />
                <MyStack gap="m">
                    {props.title && (
                        <Text variant="headline" sx={{ fontSize: ["m", "l"] }}>
                            {props.title}
                        </Text>
                    )}
                    <Text variant="normal" sx={{ fontSize: ["s", "m"] }}>
                        {props.text}
                    </Text>
                </MyStack>
            </Box>
        </Container>
    )
}

const Indicator = (props: { id: string }) => {
    const [start, end] = [`${props.id}-start`, `${props.id}-end`]
    const t = useVariableScrollRange([start, end], [-95, 0])
    const y = useTransform(t, t => `${t}%`)

    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "s",
                height: "full",
                overflow: "hidden",
            }}
        >
            <Box sx={{ variant: "position.inset0" }} bg="white"></Box>
            <motion.div
                style={{ y }}
                sx={{ variant: "position.inset0", bg: "sd.orange" }}
            ></motion.div>
        </Box>
    )
}

export const StaticTransitionCalloutInner: React.FC<Props> = props => {
    return (
        <Container px={["s", "m", "l"]}>
            <Box
                pl="l"
                p="m"
                bg="white"
                sx={{
                    position: "relative",
                    boxShadow: ".5rem .5rem 0 lightgray",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "s",
                        height: "full",
                        overflow: "hidden",
                    }}
                    bg="sd.orange"
                ></Box>
                <Stack gap="m">
                    {props.title && <Text variant="h3">{props.title}</Text>}
                    <Text variant="body">{props.text}</Text>
                </Stack>
            </Box>
        </Container>
    )
}
