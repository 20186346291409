import React from "react"

import { Overlay } from "./Overlay"
import { Modelling } from "./Modelling"
import { Foundations } from "./Foundations"
import { Governance } from "./Governance"
import { ReportImage } from "./ReportImage"
import { SmashDelta } from "../../graphics/SmashDeltaLogo"
import { Container, Link, Box } from "@theme-ui/components"

import CityLeap from "./CityLeap"

export const visualisations = {
    modelling: <Modelling />,
    "foundation-blocks": <Foundations />,
    governance: <Governance />,
    hwtw: <Overlay id="hwtw" />,
    mechanisms: <Overlay id="mechanisms" />,
    "covid-form": <ReportImage />,
    "smash-delta": <Logo />,
    "city-leap": <CityLeap />,
    "kraken-overlay": <KrakenOverlay />,
}

function Logo() {
    return (
        <Container px={["s", "m", "l"]}>
            <Link href="https://smashdelta.com/">
                <SmashDelta
                    sx={{
                        height: "xxl",
                        color: "white",
                        bg: "sd.blue",
                        p: "m",
                        boxShadow: ".5rem .5rem 0 lightgray",
                    }}
                />
            </Link>
        </Container>
    )
}

function KrakenOverlay() {
    return (
        <Box
            sx={{
                height: "60vh",
                position: "sticky",
                width: "full",
                top: "0rem",
                zIndex: 777,
                // bg: "hsla(0, 12.5%, 90.6%, 0.95)",
                backgroundImage:
                    "linear-gradient(0deg, hsla(0, 12.5%, 90.6%, 0) 0%, hsl(0, 12.5%, 90.6%) 15%)",
            }}
        />
    )
}
