/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export const SEO: React.FC = () => {
    const lang = "en"

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const metaDescription = site.siteMetadata.description
    const title = site.siteMetadata.title

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },

                {
                    property: `og:url`,
                    content: `https://tozeroandbeyond.smashdelta.com/`,
                },
                {
                    property: `og:site_name`,
                    content: `Smash Delta`,
                },
                // Image
                {
                    property: `og:image`,
                    content: `https://tozeroandbeyond.smashdelta.com/to-zero-and-beyond.png`,
                },
                {
                    property: `og:image:secure_url`,
                    content: `https://tozeroandbeyond.smashdelta.com/to-zero-and-beyond.png`,
                },
                {
                    property: `og:image:type`,
                    content: `image/png`,
                },
                {
                    property: `og:image:width`,
                    content: `1280`,
                },
                {
                    property: `og:image:height`,
                    content: `720`,
                },
                {
                    property: `og:image:alt`,
                    content: `An image from one of the conceptual 3D animations that is found in our linked whitepaper - To Zero & Beyond.`,
                },
                // VIDEO
                {
                    property: `og:video`,
                    content: `https://tozeroandbeyond.smashdelta.com/to-zero-and-beyond-720.mp4`,
                },
                {
                    property: `og:video:secure_url`,
                    content: `https://tozeroandbeyond.smashdelta.com/to-zero-and-beyond-720.mp4`,
                },
                {
                    property: `og:video:type`,
                    content: `video/mp4`,
                },
                {
                    property: `og:video:width`,
                    content: `1280`,
                },
                {
                    property: `og:video:height`,
                    content: `720`,
                },
                // Twitter
                {
                    property: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    property: `twitter:site`,
                    content: `@thesmashdaily`,
                },
                {
                    property: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    property: `twitter:title`,
                    content: title,
                },
                {
                    property: `twitter:description`,
                    content: metaDescription,
                },
                {
                    property: `twitter:image`,
                    content: `https://tozeroandbeyond.smashdelta.com/to-zero-and-beyond.png`,
                },
                {
                    property: `twitter:image:alt`,
                    content: `An image from one of the conceptual 3D animations that is found in our linked whitepaper - To Zero & Beyond.`,
                },
            ]}
        />
    )
}

export default SEO
