import React, { useEffect, useRef } from "react"
import { useTentacleCamera } from "./TentacleCamera"
import { useThree } from "react-three-fiber"
import { PerspectiveCamera, Vector3 } from "three"
import { useVariableScrollRange } from "../../services"
import { useTransform } from "framer-motion"

const pi = Math.PI
export const dist = 5
const angleX = 0.1 * pi
const sin = Math.sin
const cos = Math.cos

export const initialCameraPosition = {
    x: 0,
    y: dist * sin(angleX),
    z: dist * cos(angleX),
}

export const Camera = () => {
    useFOV()
    useTentacleCamera()
    useTechCubeCamera()
    useHWTWCamera()
    useMechanismsCamera()

    return null
}

function useFOV() {
    const { camera: threeCamera, aspect } = useThree()
    const camera = threeCamera as PerspectiveCamera

    const width = 4
    const height = 4

    useEffect(() => {
        const fov =
            Math.max(
                2 * Math.atan(width / aspect / (2 * dist)) * (180 / pi), // in degrees
                2 * Math.atan(height / (2 * dist)) * (180 / pi) // in degrees
            ) + 5
        camera.fov = fov
        camera.updateProjectionMatrix()
    }, [aspect])
}

const rx = 0.05 * pi

const cameraVec = new Vector3(0, 0, 0)

function useTechCubeCamera() {
    const { camera: threeCamera, aspect } = useThree()
    const camera = threeCamera as PerspectiveCamera

    const angleY = useVariableScrollRange(
        ["cube-top-start", "cube-top-end"],
        [-0.25 * pi, -0.75 * pi]
    )

    useEffect(
        () =>
            angleY.onChange(ry => {
                const x = sin(ry)
                const y = 0.3
                const z = cos(ry)

                cameraVec.set(x, y, z)
                cameraVec.normalize()
                cameraVec.multiplyScalar(dist)

                camera.position.copy(cameraVec)
                camera.lookAt(0, 0, 0)
            }),
        [angleY]
    )
}

function useHWTWCamera() {
    const { camera: threeCamera, aspect } = useThree()
    const camera = threeCamera as PerspectiveCamera

    const angleY = useVariableScrollRange(
        ["cube-top-end", "governance"],
        [0, -pi]
    )

    const distanceT = useVariableScrollRange(
        ["interviews-start", "interviews-end"],
        [0, 1]
    )
    const distance = useTransform(distanceT, [0, 0.5, 1], [2, 5, 5])

    useEffect(
        () =>
            angleY.onChange(ry => {
                const x = sin(ry)
                const y = 0.8
                const z = cos(ry)

                cameraVec.set(x, y, z)
                cameraVec.normalize()
                cameraVec.multiplyScalar(distance.get())

                camera.position.copy(cameraVec)
                camera.lookAt(0, 0, 0)
            }),
        [angleY, distance]
    )
}

function useMechanismsCamera() {
    const { camera: threeCamera } = useThree()
    const camera = threeCamera as PerspectiveCamera

    const angleY = useVariableScrollRange(
        ["governance-end", "outro-start"],
        [0, -0.5 * pi]
    )

    useEffect(
        () =>
            angleY.onChange(ry => {
                const x = sin(ry)
                const y = 0.6
                const z = cos(ry)

                cameraVec.set(x, y, z)
                cameraVec.normalize()
                cameraVec.multiplyScalar(dist)

                camera.position.copy(cameraVec)
                camera.lookAt(0, 0, 0)
            }),
        [angleY]
    )
}
