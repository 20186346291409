/** @jsx jsx */
import { jsx } from "theme-ui"
import { InlineVis } from "./InlineVis"
import { Modelling as ModellingData } from "../Modelling"

export const Modelling = () => {
    return (
        <InlineVis id="modelling">
            <ModellingData />
        </InlineVis>
    )
}
