/** @jsx jsx */
import { jsx } from "theme-ui"
import { Text, Container, Box } from "@theme-ui/components"

export const Footer = () => {
    return (
        <Container px="m">
            <Box py="xxxl">
                <Text variant="caption">© Smash Delta 2020</Text>
            </Box>
        </Container>
    )
}
