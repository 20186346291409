/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import BlockContent from "@sanity/block-content-to-react"
import {
    BlockRenderer,
    KeyframeRenderer,
    SpacerRenderer,
    VisualisationRenderer,
    TransitionCalloutRenderer,
    LinkRenderer,
    ListRenderer,
    ListItemRenderer,
    StoryContainer,
} from "./renderers"

const serializers = {
    types: {
        block: BlockRenderer,
        keyframe: KeyframeRenderer,
        spacer: SpacerRenderer,
        visualisation: VisualisationRenderer,
        transitionCallout: TransitionCalloutRenderer,
    },
    marks: {
        link: LinkRenderer,
    },
    container: Fragment,
    list: ListRenderer,
    listItem: ListItemRenderer,
}

export const StoryRenderer = ({ blocks }) => {
    return <BlockContent blocks={blocks} serializers={serializers} />
}
