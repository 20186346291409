/** @jsx jsx */
import { jsx } from "theme-ui"
import { Title } from "./Title"
import { StoryRenderer } from "./StoryRenderer"
import { Stack } from "./Stack"

function split(blocks: any[], predicate: (block: any) => boolean) {
    return blocks.reduce(
        (acc, val) => {
            if (predicate(val)) {
                acc.push([])
                return acc
            } else {
                acc[acc.length - 1].push(val)
                return acc
            }
        },
        [[]]
    )
}

export const Story: React.FC<{ blocks: any[] }> = ({ blocks }) => {
    const splitBlocks = split(blocks, b => b._type === "partition")

    return (
        <Stack>
            <Title />

            <Stack gap="l">
                {splitBlocks.map((blocks, i) => (
                    <Stack id="box" key={i} gap="l">
                        <StoryRenderer blocks={blocks} />
                    </Stack>
                ))}
            </Stack>
        </Stack>
    )
}
