/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Text } from "@theme-ui/components"
import { useVariableScrollRange } from "../services"
import { useTransform, AnimatePresence, motion } from "framer-motion"
import { labels } from "./ThreeScenes/TentacleLabels"
import { useState, useEffect } from "react"
import { Stack } from "raam"

export const DetailedLabels = () => {
    const _t = useVariableScrollRange(
        ["kraken-spin-start", "kraken-spin-end"],
        [0, 1]
    )

    const t = useTransform(
        _t,
        [0, 0.2, 0.8, 1],
        [-1, 0, labels.length, labels.length + 1],
        { ease: t => t }
    )

    const [label, setLabel] = useState(null)

    useEffect(
        () =>
            t.onChange(t => {
                if (t <= 0 || t >= labels.length) {
                    setLabel(null)
                } else {
                    const i = Math.floor(t)
                    setLabel(labels[i])
                }
            }),
        [setLabel, t]
    )

    return (
        <AnimatePresence>
            {label && <Label key={label.headline} {...label} />}
        </AnimatePresence>
    )
}

const Label: React.FC<{ headline: string; text: string; spread?: boolean }> = ({
    headline,
    text,
    spread = false,
}) => {
    return (
        <motion.div
            sx={{
                position: "absolute",
                top: "52%",
                left: ["2%", "50%", null],
                maxWidth: ["96%", "48%", null],
            }}
            initial={{ x: "-50%", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "50%", opacity: 0 }}
        >
            <Box
                sx={{
                    boxShadow: ".5rem .5rem 0px lightgray",
                }}
                p="m"
                bg={spread ? "sd.blue" : "white"}
                color={spread ? "sd.beige" : "sd.black"}
            >
                <Stack gap="m">
                    <Text variant="headline">{headline}</Text>
                    <Text variant="body">{text}</Text>
                </Stack>
            </Box>
        </motion.div>
    )
}
