import React, { useRef, useState, useEffect } from "react"
import {} from "react-three-fiber"
import { Shape, DoubleSide, Mesh, Group } from "three"
import {
    useVariableScrollRange,
    useOnChange,
    useWithinScrollRange,
    useAfterScrollPoint,
} from "../../services"
import { useTransform } from "framer-motion"

const wall = new Shape()

const outerWidth = 5
const wallWidth = 0.2

const innerWidth = outerWidth - wallWidth
const op = outerWidth / 2
wall.moveTo(-op, op)
wall.lineTo(op, op)
wall.lineTo(op, -op)
wall.lineTo(-op, -op)
wall.lineTo(-op, op)

const hole = new Shape()
const ip = innerWidth / 2

hole.moveTo(-ip, ip)
hole.lineTo(ip, ip)
hole.lineTo(ip, -ip)
hole.lineTo(-ip, -ip)
hole.lineTo(-ip, ip)

wall.holes.push(hole)

const extrudeSettings = {
    depth: 2,
    bevelEnabled: false,
}

const pi = Math.PI

export const TechWall = () => {
    const visible = useAfterScrollPoint("mech3-start")
    const scaleZ = useVariableScrollRange(["mech3-start", "mech3-end"], [0, 1])
    const mesh = useRef<Mesh>(null!)

    useOnChange(scaleZ, s => {
        mesh.current.scale.z = s
    })

    return (
        <group visible={visible} position-y={-1}>
            <mesh ref={mesh} rotation-x={-0.5 * pi} scale-z={scaleZ.get()}>
                <extrudeBufferGeometry
                    attach="geometry"
                    args={[[wall], extrudeSettings]}
                />
                <meshPhongMaterial
                    attach="material"
                    color={0x666666}
                    side={DoubleSide}
                />
            </mesh>
        </group>
    )
}

export const TechSphere = () => {
    const visible = useAfterScrollPoint("mech2-start")

    const scale = useVariableScrollRange(["mech2-start", "mech2-end"], [0, 2.2])
    const group = useRef<Group>(null!)

    const s = scale.get()

    useOnChange(scale, s => {
        group.current.scale.set(s, s, s)
    })

    return (
        <group ref={group} visible={visible} scale={[s, s, s]}>
            <mesh>
                <icosahedronBufferGeometry attach="geometry" args={[1.01, 1]} />
                <meshBasicMaterial
                    transparent={true}
                    attach="material"
                    color={0xffffff}
                    wireframe={true}
                    wireframeLinewidth={2}
                />
            </mesh>
            <mesh>
                <icosahedronBufferGeometry attach="geometry" args={[1.01, 1]} />
                <meshPhongMaterial
                    flatShading={true}
                    transparent={true}
                    opacity={0.4}
                    attach="material"
                    color={0xffff00}
                />
            </mesh>
        </group>
    )
}
