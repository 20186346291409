/** @jsx jsx */
import { jsx } from "theme-ui"
import { Grid } from "@theme-ui/components"

export function Stack({
    horizontal = false,
    alignY = "stretch",
    alignX = "stretch",
    gap = "0px",
    ...props
}) {
    return (
        <Grid
            sx={{
                gridAutoFlow: horizontal ? "column" : "row",
                alignItems: alignY,
                justifyItems: alignX,
            }}
            gap={gap}
            {...props}
        />
    )
}
