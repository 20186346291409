/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Text } from "@theme-ui/components"
import { motion } from "framer-motion"

export const FoundationBox: React.FC<{ variant?: string }> = ({
    children,
    variant,
}) => {
    const variants = {
        hidden: {
            opacity: 0,
            scale: 0,
        },
        unfocus: {
            opacity: 0.2,
            scale: 0.8,
        },
        focus: {
            opacity: 1,
            scale: 1,
        },
    }

    return (
        <motion.div variants={variants} animate={variant}>
            <Box
                p={["m", "m", "l"]}
                m="s"
                sx={{
                    boxShadow: ".5rem .5rem 0 #121212",
                }}
                bg="sd.orange"
                color="sd.white"
            >
                <Text variant="h3">{children}</Text>
            </Box>
        </motion.div>
    )
}

export const StaticFoundationBox: React.FC = ({ children }) => {
    return (
        <Box
            p={["m", "m", "l"]}
            m="s"
            sx={{
                boxShadow: ".5rem .5rem 0 #121212",
            }}
            bg="sd.orange"
            color="sd.white"
        >
            <Text variant="h3">{children}</Text>
        </Box>
    )
}
