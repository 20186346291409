/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex, Link } from "@theme-ui/components"
import { SmashDelta } from "../graphics/SmashDeltaLogo"

export const Header = props => (
    <Flex
        sx={{ position: "sticky", top: 0, zIndex: 999 }}
        p="m"
        as="header"
        {...props}
    >
        <Link href="https://smashdelta.com/">
            <SmashDelta sx={{ height: "l" }} />
        </Link>
    </Flex>
)
