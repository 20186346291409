/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import { useSetScrollPoint } from "../../services"

export const Overlay: React.FC<{ id: string }> = ({ id }) => {
    const ref = useSetScrollPoint(id)

    return (
        <Box
            ref={ref}
            sx={{
                height: "60vh",
                position: "sticky",
                width: "full",
                top: "0rem",
                zIndex: 777,
                bg:
                    id === "mechanisms"
                        ? undefined
                        : "hsla(0, 12.5%, 90.6%, 0.95)",
            }}
        />
    )
}
