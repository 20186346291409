import React, { Suspense, useRef } from "react"
import { KrakenModel } from "./KrakenModel"
import { Mesh } from "three"

export const Kraken = () => {
    return (
        <Suspense fallback={null}>
            <KrakenModel />
        </Suspense>
    )
}

export const Loading = () => {
    const mesh = useRef<Mesh>(null!)

    return (
        <mesh ref={mesh} scale={[1, 1, 1]} position={[0, 0, 0]}>
            <icosahedronBufferGeometry attach="geometry" args={[1, 1]} />
            <meshPhongMaterial attach="material" color={0x881212} flatShading />
        </mesh>
    )
}
