import { useEffect } from "react"

function setWindowHeightCSSVariable() {
    let windowHeight = window.innerHeight
    document.documentElement.style.setProperty(
        "--window-height",
        windowHeight + "px"
    )
}

export function useWindowHeightCssVariable() {
    useEffect(() => {
        setWindowHeightCSSVariable()

        window.addEventListener("resize", setWindowHeightCSSVariable)
        return () => {
            window.removeEventListener("resize", setWindowHeightCSSVariable)
        }
    }, [])
}
