/** @jsx jsx */
import { jsx } from "theme-ui"
import { Container } from "@theme-ui/components"
// @ts-ignore
import source from "../../images/orange-and-blue.gif"

import LazyLoad from "react-lazyload"

const OrangeAndBlue = () => {
    return (
        <Container px={["s", "m", "l"]}>
            <LazyLoad once height={340} offset={500}>
                <img
                    src={source}
                    sx={{ width: "100%", maxWidth: "100%" }}
                    alt="Conceptual animation for interviews and bluetooth tracing."
                />
            </LazyLoad>
        </Container>
    )
}

export default OrangeAndBlue
