import create from "zustand"
import { useEffect, useState } from "react"
import { useMeasure } from "./useMeasure"
import { produce } from "immer"
import { useViewportScroll, useTransform } from "framer-motion"
import { range as d3range } from "d3"

type ScrollPointState = {
    ready: boolean
    keyframes: string[]
    points: Record<string, number>
    set: any
}

export const [useScrollPoints] = create<ScrollPointState>(set => ({
    ready: false,
    keyframes: [],
    points: {
        zero: 0,
    },
    set: fn => set(produce(fn)),
}))

const threshold = 0.7

export function useSetScrollPoint(id: string) {
    const [ref, bounds] = useMeasure()

    const set = useScrollPoints(s => s.set)

    useEffect(() => {
        if (!bounds.width && !bounds.height && !bounds.top) {
            // bounds is returned before its measured
            // so one time around its all 0
            return
        }

        const innerHeight = window.outerHeight - 20 // use outer height so it doesnt switch on scroll
        const scrollY = window.scrollY
        const boundsTop = bounds.top
        const scrollYAtThreshold = scrollY + boundsTop - threshold * innerHeight

        set(state => {
            if (Math.abs(state.points[id] - scrollYAtThreshold) < 32) {
                return
            }
            state.points[id] = scrollYAtThreshold
        })
    }, [ref, bounds, id, set])

    return ref
}

export function useSetScrollPointWithChild(topID: string, bottomID: string) {
    const [ref, bounds] = useMeasure()
    const [childRef, childBounds] = useMeasure()

    const set = useScrollPoints(s => s.set)

    useEffect(() => {
        if (!bounds.width && !bounds.height && !bounds.top) {
            // bounds is returned before its measured
            // so one time around its all 0
            return
        }
        if (!childBounds.width && !childBounds.height && !childBounds.top) {
            // bounds is returned before its measured
            // so one time around its all 0
            return
        }

        const innerHeight = window.outerHeight - 20
        const scrollY = window.scrollY
        const boundsTop = bounds.top

        const scrollYAtThreshold = scrollY + boundsTop - threshold * innerHeight

        set(state => {
            if (Math.abs(state.points[topID] - scrollYAtThreshold) < 32) {
                return
            } else state.points[topID] = scrollYAtThreshold
            state.points[bottomID] =
                scrollYAtThreshold + bounds.height - childBounds.height
        })
    }, [bounds, topID, bottomID, set, childBounds])

    return [ref, childRef] as const
}

export function useScrollRange(start: string, end: string, clamp = true) {
    const { scrollY } = useViewportScroll()
    const range = useScrollPoints(s => [s.points[start], s.points[end]])
    return useTransform(scrollY, range, [0, 1], { clamp })
}

export function useVariableScrollRange(
    keyframes: string[],
    output: number[],
    offsets?: number[]
) {
    const { scrollY } = useViewportScroll()
    const ranges = useScrollPoints(s =>
        keyframes.map((k, i) => s.points[k] + (offsets ? offsets[i] : 0))
    )
    return useTransform(scrollY, ranges, output)
}

export function useWithinScrollRange(
    keyframeStart: string,
    keyframeEnd: string,
    offsets = [0, 0]
) {
    const { scrollY } = useViewportScroll()
    const initScrollY = scrollY.get()

    const [start, end] = useScrollPoints(s => [
        s.points[keyframeStart] + offsets[0],
        s.points[keyframeEnd] + offsets[1],
    ])

    const initWithinRange = initScrollY > start && initScrollY < end
    const [inRange, setInRange] = useState(initWithinRange)

    useEffect(
        () =>
            scrollY.onChange(s => {
                setInRange(s > start && s < end)
            }),
        [scrollY, setInRange, start, end]
    )

    return inRange
}

export function useAfterScrollPoint(keyframe: string, offset = 0) {
    return useWithinScrollRange(keyframe, keyframe, [offset, Infinity])
}
