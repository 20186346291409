// Generated by https://quicktype.io
export interface ModelRow {
    "": number
    "Not infected": number
    Exposed: number
    Infective_mild: number
    Hospitalized_infective: number
    Recovering_mild: number
    Recovering_severe: number
    Recovered: number
    Dead: number
    Hospitalized: number
    "Number infected": number
}

import dataAJson from "./covid_ballroom.csv.json"
import dataBJson from "./covid_dungeon_rave.csv.json"
import dataCJson from "./covid_salsa.csv.json"

export const dataPre = (dataAJson as ModelRow[]).filter((d, i) => i <= 150)
export const dataA = (dataAJson as ModelRow[]).filter((d, i) => i >= 150)
export const dataB = (dataBJson as ModelRow[]).filter((d, i) => i >= 150)

export const dataC = (dataCJson as ModelRow[]).filter((d, i) => i >= 150)
