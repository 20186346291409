import React, { useRef } from "react"
import {} from "react-three-fiber"
// import { useVariableScrollRange, useOnChange } from "../../services"
// import { SpotLight } from "three"

export function Lights() {
    return <RegularLights />
}

function RegularLights() {
    return (
        <>
            <directionalLight args={[0xffffff, 0.8]} position={[15, 30, 15]} />
            <ambientLight args={[0xffffff, 0.1]} />
        </>
    )
}

// const pi = Math.PI

// const deg = (n: number) => n * (pi / 180)

// function MechanismLights() {
//     const t = useVariableScrollRange(
//         ["governance-end", "mechanisms-start", "mechanisms-end"],
//         [0, 1, 2]
//     )

//     const lightA = useRef<SpotLight>(null!)
//     const lightB = useRef<SpotLight>(null!)
//     const lightC = useRef<SpotLight>(null!)

//     useOnChange(t, t => {
//         lightA.current.visible = t >= 1.3
//         lightB.current.visible = t >= 1.3
//         lightC.current.visible = t >= 1.3
//     })

//     return (
//         <>
//             <spotLight
//                 ref={lightA}
//                 visible={false}
//                 args={[0xffffaa, 1]}
//                 position={[3, 2, 0]}
//                 angle={deg(15)}
//                 penumbra={0.4}
//                 distance={0}
//             />

//             <mesh position={[3, 2, 0]}>
//                 <coneBufferGeometry attach="geometry" args={[0.3, 0.5, 12]} />
//                 <meshNormalMaterial attach="material" />
//             </mesh>

//             <spotLight
//                 ref={lightB}
//                 visible={false}
//                 args={[0xffffaa, 1]}
//                 position={[-3, 3, 1]}
//                 angle={deg(15)}
//                 penumbra={0.4}
//                 distance={0}
//             />

//             <spotLight
//                 ref={lightC}
//                 visible={false}
//                 args={[0xffffaa, 0.8]}
//                 position={[0, 1, 3]}
//                 angle={deg(26)}
//                 penumbra={0.8}
//                 distance={0}
//             />

//             <ambientLight args={[0xcccccc, 0.05]} />
//         </>
//     )
// }
