/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Text, Container, Link, Grid } from "@theme-ui/components"
import { ScrollPoint } from "./ScrollPoint"
import { visualisations } from "./visualisations"
import { visualisations as staticVisualisations } from "./visualisations/staticVisualisations"
import {
    TransitionCallout,
    StaticTransitionCalloutInner,
} from "./TransitionCallout"
import { Stack } from "raam"
import OrangeAndBlue from "./visualisations/OrangeBlue"
import Personal from "./visualisations/Green"

export const BlockRenderer = ({ children, node }) => {
    const { style = "normal", _key } = node

    let variant = style
    if (style === "normal") {
        variant = "body"
    } else if (style === "normal-s") {
        variant = "body-s"
    }

    const text = (
        <Text key={_key} variant={variant}>
            {children}
        </Text>
    )

    const child =
        style === "callout" ? (
            <Box
                p={["m", "l"]}
                bg="sd.black"
                color="sd.beige"
                sx={{ boxShadow: ".5rem .5rem 0px gray" }}
            >
                {text}
            </Box>
        ) : (
            text
        )

    return <Container px={["s", "m", "l"]}>{child}</Container>
}

export const KeyframeRenderer = props => {
    const { name, id, _key } = props.node

    return (
        <Container px={["s", "m", "l"]}>
            <ScrollPoint key={_key} id={id} name={name} />
        </Container>
    )
}

export const SpacerRenderer = props => {
    const { size } = props.node
    return <Box sx={{ height: size }} />
}

export const VisualisationRenderer = props => {
    const component = visualisations[props.node.id]
    return component ? component : null
}

export const StaticVisualisationRenderer = props => {
    const component = staticVisualisations[props.node.id]
    return component ? component : null
}

export const TransitionCalloutRenderer = props => {
    const { id, title = "", text = "", height = "100vh" } = props.node
    return (
        <TransitionCallout id={id} title={title} text={text} height={height} />
    )
}

export const StaticTransitionCalloutRenderer = props => {
    if (props.node.id === "interviews") {
        return (
            <Stack gap="m">
                <OrangeAndBlue />
                <StaticTransitionCalloutInner {...props.node} />
            </Stack>
        )
    }
    if (props.node.id === "personal") {
        return (
            <Stack gap="m">
                <Personal />
                <StaticTransitionCalloutInner {...props.node} />
            </Stack>
        )
    }
    return <StaticTransitionCalloutInner {...props.node} />
}

export const LinkRenderer = props => {
    return (
        <Link as="a" color="sd.blue" href={props.mark.href}>
            {props.children}
        </Link>
    )
}

export const ListRenderer = props => {
    return (
        <Container px={props.level === 1 ? ["s", "m", "l"] : 0}>
            <Grid pl="m" gap="m" as="ul">
                {props.children}
            </Grid>
        </Container>
    )
}

export const ListItemRenderer = props => {
    return (
        <Text variant="body" as="li">
            {props.children}
        </Text>
    )
}

export const StoryContainer: React.FC = props => {
    return <Stack gap="l">{props.children}</Stack>
}
