/** @jsx jsx */
import { jsx } from "theme-ui"
import { motion } from "framer-motion"

export function SmashDelta(props) {
    return (
        <svg viewBox="0 0 400 185" fill="currentColor" {...props}>
            <g>
                <g id="smash-d_lta">
                    <path
                        id="S"
                        d="M16.035 44.455a30.088 30.088 0 01-9.772-8.324A19.69 19.69 0 012.45 23.938a19.08 19.08 0 014.092-12.193 25.88 25.88 0 0111.33-7.961A45.155 45.155 0 0134.185 1 39.116 39.116 0 0149.94 4.006 24.813 24.813 0 0160.77 12.72a23.43 23.43 0 013.925 13.612H43.984a8.44 8.44 0 00-2.645-6.541c-1.763-1.615-4.314-2.422-7.655-2.422a15.59 15.59 0 00-5.735.946 8.518 8.518 0 00-3.619 2.478 5.272 5.272 0 00-1.224 3.368c0 2.042 1.178 3.786 3.535 5.234A53.884 53.884 0 0037.47 33.96c8.944 2.895 15.709 6.496 20.294 10.801a21.618 21.618 0 016.875 16.48 20.864 20.864 0 01-3.73 12.527 22.898 22.898 0 01-10.523 7.85 43.534 43.534 0 01-15.979 2.673 46.782 46.782 0 01-16.786-2.979A28.53 28.53 0 014.9 71.987C1.633 67.755 0 62.447 0 56.063h20.878a14.55 14.55 0 001.476 7.127 8.105 8.105 0 004.426 3.702 22.457 22.457 0 007.627 1.086c3.23 0 5.615-.64 7.155-1.921a5.902 5.902 0 002.31-4.705 7.618 7.618 0 00-1.002-4.036 10.387 10.387 0 00-3.925-3.257 56.014 56.014 0 00-8.713-3.563 89.374 89.374 0 01-14.197-6.041z"
                    />
                    <path
                        id="M"
                        d="M162.684 83.177h-20.822v-15.31l2.004-41.2-18.651 56.51H111.63l-18.652-56.51 2.06 41.2v15.31H74.216V2.114h27.336l16.87 53.783 16.87-53.783h27.392v81.063z"
                    />
                    <path
                        id="A"
                        d="M220.586 69.76H194.53l-4.12 13.417h-22.325l29.563-81.063h19.709l29.731 81.064h-22.382l-4.12-13.418zm-5.177-16.815l-7.906-25.61-7.851 25.61h15.757z"
                    />
                    <path
                        id="S_2"
                        d="M266.074 44.455a30.111 30.111 0 01-9.771-8.324 19.692 19.692 0 01-3.814-12.193 19.08 19.08 0 014.092-12.192 25.888 25.888 0 0111.33-7.962A45.162 45.162 0 01284.224 1a39.117 39.117 0 0115.757 3.006 24.803 24.803 0 0110.828 8.714 23.434 23.434 0 013.926 13.612h-20.712a8.422 8.422 0 00-2.644-6.541c-1.763-1.615-4.315-2.422-7.656-2.422a15.59 15.59 0 00-5.734.946 8.52 8.52 0 00-3.619 2.478 5.27 5.27 0 00-1.225 3.368c0 2.042 1.178 3.786 3.535 5.234a53.919 53.919 0 0010.829 4.565c8.946 2.895 15.71 6.496 20.294 10.801a21.617 21.617 0 016.876 16.48 20.86 20.86 0 01-3.73 12.527 22.9 22.9 0 01-10.523 7.85 43.529 43.529 0 01-15.979 2.673 46.79 46.79 0 01-16.786-2.979 28.533 28.533 0 01-12.722-9.325c-3.267-4.232-4.9-9.54-4.9-15.924h20.878a14.554 14.554 0 001.476 7.127 8.113 8.113 0 004.426 3.702 22.46 22.46 0 007.628 1.086c3.229 0 5.614-.64 7.154-1.921a5.897 5.897 0 002.311-4.705 7.616 7.616 0 00-1.002-4.036 10.38 10.38 0 00-3.925-3.257 56.008 56.008 0 00-8.713-3.563 89.383 89.383 0 01-14.198-6.041z"
                    />
                    <path
                        id="H"
                        d="M392.792 2.114v81.064h-20.711V50.329h-27.059v32.848h-20.767V2.114h20.767V33.57h27.059V2.114h20.711z"
                    />
                    <path
                        id="D"
                        d="M47.338 106.804a36.456 36.456 0 0113.843 14.3 42.338 42.338 0 015.005 20.652v3.145a42.362 42.362 0 01-4.976 20.624 36.488 36.488 0 01-13.73 14.272 38.078 38.078 0 01-19.678 5.149H0v-83.291h27.287a39.087 39.087 0 0120.05 5.149zm-3.003 34.838c0-7.475-1.45-13.129-4.349-16.961-2.898-3.832-7.131-5.749-12.699-5.749h-5.95v48.795h6.465c5.3 0 9.382-1.907 12.242-5.721 2.86-3.813 4.29-9.514 4.29-17.104v-3.26z"
                    />
                    <path
                        id="L"
                        d="M273.15 167.757v17.218h-55.659v-83.291h21.338v66.073h34.321z"
                    />
                    <path
                        id="T"
                        d="M329.959 118.961h-24.827v66.014h-21.394v-66.014H259.54v-17.277h70.419v17.277z"
                    />
                    <path
                        id="A_2"
                        d="M372.77 171.188h-26.772l-4.233 13.787h-22.94l30.377-83.291h20.25L400 184.975h-22.996l-4.234-13.787zm-3.238-17.275l-8.124-26.314-8.065 26.314h16.189z"
                    />
                </g>
                <g id="triple-bar">
                    <motion.path
                        animate={{ opacity: [1, 0] }}
                        transition={{
                            duration: 1,
                            yoyo: Infinity,
                            repeatDelay: 1,
                        }}
                        id="top"
                        d="M74.216 101.611H207.53v17.321H74.216v-17.321z"
                    />
                    <motion.path
                        animate={{ opacity: [1, 0] }}
                        transition={{
                            delay: 0.3,
                            duration: 1,
                            yoyo: Infinity,
                            repeatDelay: 1,
                        }}
                        id="middle"
                        d="M74.216 134.618H207.53v17.321H74.216v-17.321z"
                    />
                    <motion.path
                        animate={{ opacity: [1, 0] }}
                        transition={{
                            delay: 0.6,
                            duration: 1,
                            yoyo: Infinity,
                            repeatDelay: 1,
                        }}
                        id="bottom"
                        d="M74.216 167.727H207.53v17.219H74.216v-17.219z"
                    />
                </g>
            </g>
        </svg>
    )
}
