import React from "react"
import { useWithinScrollRange } from "../../services"
import { Dom } from "react-three-fiber"

export const labels = [
    {
        headline: "Treatment",
        text: "Hospitalisation and therapeutics",
        position: [1.1, -0.7, 0.4],
        camera: {
            x: -0.6459971514378993,
            y: -0.25526046783824635,
            z: 1.7542586204868682,
        },
    },
    {
        headline: "Immunology",
        text: "Finding a vaccine and driving population immunity",
        position: [1.3, -0.7, -0.5],
        camera: {
            x: -1.6809364352464429,
            y: -0.3058985410001873,
            z: 0.800482552548348,
        },
    },
    {
        headline: "Protection and PPE",
        text:
            "Supply / innovation of personal and other protective equipment to protect workers across health and other sectors",
        position: [-0.7, -0.7, 1.4],
        camera: {
            x: -1.9378011398557622,
            y: -0.22652007765971224,
            z: -0.7511529570504083,
        },
    },
    {
        headline: "Society & Public Wellbeing",
        text: "Supporting social connectedness and mental health",
        position: [-1.3, -0.7, 0.4],
        camera: {
            x: -0.9531867755491444,
            y: -0.2822183194504276,
            z: -2.092308985226162,
        },
    },
    {
        headline: "Communication",
        text:
            "Ensuring all of society is operating in the same reality, with the same facts",
        position: [-1.5, -0.7, -0.55],
        camera: {
            x: 0.6340792055988964,
            y: -0.2552604678382357,
            z: -1.7586014864787487,
        },
    },
    {
        headline: "Economy and labour markets",
        text: "Reigniting the flow of commerce and getting people back to work",
        position: [-0.5, -0.7, -1.5],
        camera: {
            x: 1.770862922790528,
            y: -0.2686952293034056,
            z: -0.8580954213494282,
        },
    },
    {
        headline: "Supply chains",
        text:
            "Securing the creation and movement of manufacturing inputs across geographies",
        position: [0.9, -0.7, -1.5],
        camera: {
            x: 2.1382464858933963,
            y: -0.15705785566790073,
            z: 0.8255877904394401,
        },
    },
    {
        headline: "Stopping the spread",
        text: `Epidemiological research alongside government and public response to minimise transmission: (e.g. testing, contact tracing and changes in behaviour)`,
        position: [0.6, -0.7, 1.5],
        camera: {
            x: 1.0163259133083562,
            y: -9.826827159429319e-16,
            z: 2.3569225697042935,
        },
        spread: true,
    },
]

export function TentacleLabels() {
    const show = useWithinScrollRange("kraken-spin-end", "kraken-end", [0, -50])

    return show ? (
        <>
            {labels
                .filter(l => l.headline !== "Stopping the spread")
                .map((l, i) => (
                    <Label key={i} {...l} />
                ))}
            {labels
                .filter(l => l.headline === "Stopping the spread")
                .map((l, i) => (
                    <Label key="stopping" {...l} />
                ))}
        </>
    ) : null
}

function Label({ position, headline }) {
    let special = headline === "Stopping the spread" ? "kraken--special" : ""

    return (
        <Dom position={position} center>
            <div className={`kraken ${special}`}>
                <div className="kraken__headline">{headline}</div>
            </div>
        </Dom>
    )
}
