import React from "react"
import { motion } from "framer-motion"

const Icon = props => (
    <svg fill="none" {...props} viewBox="0 0 24 24">
        <motion.path
            animate={{ opacity: [1, 0] }}
            transition={{
                duration: 1,
                yoyo: Infinity,
                repeatDelay: 1,
            }}
            d="M2 2l10 8 10-8"
            stroke="#000"
        />
        <motion.path
            animate={{ opacity: [1, 0] }}
            transition={{
                delay: 0.3,
                duration: 1,
                yoyo: Infinity,
                repeatDelay: 1,
            }}
            d="M2 8l10 8 10-8"
            stroke="#000"
        />
        <motion.path
            animate={{ opacity: [1, 0] }}
            transition={{
                delay: 0.6,
                duration: 1,
                yoyo: Infinity,
                repeatDelay: 1,
            }}
            d="M2 14l10 8 10-8"
            stroke="#000"
        />
    </svg>
)

export default Icon
