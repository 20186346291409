/** @jsx jsx */
import { jsx } from "theme-ui"
import { Container } from "@theme-ui/components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export const query = graphql`
    query {
        fileName: file(relativePath: { eq: "kraken.png" }) {
            childImageSharp {
                fluid(maxWidth: 1644, maxHeight: 1016) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export const StaticKraken = () => {
    const data = useStaticQuery(query)

    return (
        <Container>
            <Img
                sx={{ maxWidth: "100%" }}
                fluid={data.fileName.childImageSharp.fluid}
                alt="Covid Report Form"
            />
        </Container>
    )
}
