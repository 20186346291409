/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex, Text, Container } from "@theme-ui/components"
import { Stack } from "raam"
import { useStaticQuery, graphql } from "gatsby"

import ScrollDown from "./ScrollDown"

export const Title = props => {
    const data = useStaticQuery(graphql`
        query ContactTracingStoryTitle {
            sanityStory(title: { eq: "TO ZERO & BEYOND" }) {
                id
                title
                subtitle
            }
        }
    `)

    const { title, subtitle } = data.sanityStory

    return (
        <Flex
            variant="flex.center"
            sx={{
                height: `calc(100vh - 5rem)`,
                zIndex: 999,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
            {...props}
        >
            <Container px="m" variant="inline-vis">
                <div></div>
                <Stack gap="l">
                    <Text
                        variant="headline"
                        sx={{ fontSize: ["10vw", "10vw", 120] }}
                    >
                        {title}
                    </Text>
                    <Text
                        variant="subheadline"
                        sx={{ fontSize: ["4vw", "4vw", 48] }}
                    >
                        {subtitle}
                    </Text>
                </Stack>
            </Container>

            <ScrollDown sx={{ width: "l", height: "l", mt: ["xl", "xxl"] }} />
        </Flex>
    )
}
