/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import { Header, Story } from "../components"
import { useWindowHeightCssVariable, useScrollPoints } from "../services"
import { Global, css } from "@emotion/core"
import { ThreeCanvas } from "../components/ThreeCanvas"
import { SideThreeCanvas } from "../components/SideThreeCanvas"
import { Footer } from "../components/Footer"
import { useStaticQuery, graphql } from "gatsby"
import { flatten } from "lodash"
import { SEO } from "../components/seo"

import { ErrorBoundary } from "../components/ErrorBoundary"
import { Fragment, useEffect } from "react"
import { useDetectIE } from "../services/useDetectIE"
import { StaticStory } from "../components/StaticStory"

function IndexPage() {
    useWindowHeightCssVariable()

    const isIE = useDetectIE()

    useEffect(() => {
        // @ts-ignore
        if (typeof window !== "undefined" && window.gtag) {
            // @ts-ignore
            window.gtag("event", "page_view", {
                page_title: "To Zero & Beyond",
                page_path: "/tozeroandbeyond",
            })
        } else {
            console.warn("window.gtag is not availble")
        }
    }, [])

    return (
        <Box bg="sd.beige" color="black" as="main">
            <GlobalCSS />

            <SEO />

            <Header color="sd.black" />

            {isIE ? (
                <StaticStory />
            ) : (
                <ErrorBoundary>
                    <InteractiveStory />
                </ErrorBoundary>
            )}

            <Footer />
        </Box>
    )
}

function InteractiveStory() {
    const data = useStaticQuery(graphql`
        query ContactTracingStory {
            sanityStory(title: { eq: "TO ZERO & BEYOND" }) {
                id
                title
                _rawContent
            }
        }
    `)

    const set = useScrollPoints(state => state.set)
    const ready = useScrollPoints(state => state.ready)

    const blocks = data.sanityStory._rawContent

    const keyframes = flatten(
        blocks
            .filter(block =>
                ["keyframe", "transitionCallout", "visualisation"].includes(
                    block._type
                )
            )
            .map(block =>
                block._type === "transitionCallout"
                    ? [`${block.id}-start`, `${block.id}-end`]
                    : block.id
            )
    ) as string[]

    set(state => {
        state.keyframes = keyframes

        keyframes.forEach((keyframe, i) => {
            if (!state.points[keyframe]) {
                state.points[keyframe] = Infinity
            }
        })

        state.ready = true
    })

    return (
        <Fragment>
            <ThreeCanvas ready={ready} />

            <SideThreeCanvas />

            <Story blocks={blocks} />
        </Fragment>
    )
}

function GlobalCSS() {
    return (
        <Global
            styles={css`
                 {
                    body {
                        width: 100%;
                        height: 100%;
                        margin: 0;
                    }
                    html {
                        font-size: calc(1rem + 0.25vw);
                        background-color: #eae4e4;
                    }

                    .kraken {
                        text-align: center;
                        max-width: 20ch;
                        background-color: rgba(234, 228, 228, 0.8);
                    }

                    .kraken--hide {
                        display: none;
                    }

                    .kraken__headline {
                        font-family: "Heebo", sans-serif;
                        text-transform: uppercase;
                        font-weight: 900;
                        font-size: 0.8rem;
                    }

                    .kraken--special {
                        color: #3353ff;
                    }
                }
            `}
        />
    )
}

export default IndexPage
