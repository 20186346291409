/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import { Canvas } from "react-three-fiber"
import { Kraken } from "./ThreeScenes/Kraken"
import { TechCube } from "./ThreeScenes/TechCube"
import { Lights } from "./ThreeScenes/Lights"
import {
    useWithinScrollRange,
    useAfterScrollPoint,
    useVariableScrollRange,
    useOnChange,
} from "../services"
import { Camera, initialCameraPosition } from "./ThreeScenes/Camera"
import { HWTW } from "./ThreeScenes/HWTW"
import { TechWall, TechSphere } from "./ThreeScenes/TechWall"
import { useRef, Fragment } from "react"
import { Group } from "three"
import { DetailedLabels } from "./DetailedLabels"

const position: [number, number, number] = [
    initialCameraPosition.x,
    initialCameraPosition.y,
    initialCameraPosition.z,
]

export const ThreeCanvas: React.FC<{ ready: boolean }> = ({ ready }) => {
    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: 888,
                width: "full",
                height: "60vh",
                pointerEvents: "none",
            }}
        >
            <Canvas
                pixelRatio={2}
                colorManagement
                camera={{
                    position,
                }}
            >
                <Lights />

                <Camera />

                <Kraken />

                {ready && (
                    <Fragment>
                        <MainTechCube />

                        <HWTW />
                    </Fragment>
                )}
            </Canvas>

            <DetailedLabels />
        </Box>
    )
}

const MainTechCube = () => {
    const methodsTechCube = useWithinScrollRange(
        "cube-top-start",
        "cube-top-end"
    )
    const mechanismsTechCube = useAfterScrollPoint("governance-end")
    const showTechCube = methodsTechCube || mechanismsTechCube

    const group = useRef<Group>(null!)

    const scale = useVariableScrollRange(
        ["mechanisms-end", "outro-start"],
        [0.5, 0]
    )

    useOnChange(scale, s => {
        group.current.scale.set(s, s, s)
    })

    return (
        <group ref={group} visible={showTechCube} scale={[0.5, 0.5, 0.5]}>
            <TechCube />
            <TechSphere />
            <TechWall />
        </group>
    )
}
