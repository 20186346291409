import { colors } from "../theme/colors"
import { transform } from "framer-motion"
import { randomUniform } from "d3"
const techColors = colors.tech

export type Activity = {
    x: number
    z: number
    stage: "interviews" | "bluetooth" | "personal"
    color: string
    t: number
    type: "dot" | "square"
}

export const activities: Activity[] = [
    {
        x: 2,
        z: 2,
        stage: "interviews",
        color: techColors.interviews,
        t: 0.1,
        type: "dot",
    },
    {
        x: 4,
        z: 4,
        stage: "interviews",
        color: techColors.interviews,
        t: 0.3,
        type: "dot",
    },
    {
        x: 4.5,
        z: 4.5,
        stage: "interviews",
        color: techColors.interviews,
        t: 0.35,
        type: "square",
    },
    {
        x: 1.5,
        z: 4.5,
        stage: "interviews",
        color: techColors.interviews,
        t: 0.45,
        type: "square",
    },
    {
        x: -2,
        z: 3,
        stage: "interviews",
        color: techColors.interviews,
        t: 0.5,
        type: "dot",
    },
    {
        x: -3,
        z: 2,
        stage: "bluetooth",
        color: techColors.bluetooth,
        t: 1.2,
        type: "dot",
    },
    {
        x: -3,
        z: 1,
        stage: "bluetooth",
        color: techColors.bluetooth,
        t: 1.3,
        type: "dot",
    },
    {
        x: -1,
        z: -2,
        stage: "bluetooth",
        color: techColors.bluetooth,
        t: 1.6,
        type: "dot",
    },
    {
        x: 1,
        z: -3,
        stage: "bluetooth",
        color: techColors.bluetooth,
        t: 1.8,
        type: "dot",
    },
    {
        x: -0.5,
        z: -1.5,
        stage: "personal",
        color: techColors.personal,
        type: "square",
        t: 2.25,
    },
    {
        x: -1.5,
        z: 0.5,
        stage: "personal",
        color: techColors.personal,
        type: "square",
        t: 2.35,
    },
    {
        x: 2.5,
        z: 1.5,
        stage: "personal",
        color: techColors.personal,
        type: "square",
        t: 2.6,
    },
    {
        x: 3.5,
        z: 1.5,
        stage: "personal",
        color: techColors.personal,
        type: "square",
        t: 2.7,
    },
    {
        x: 4.5,
        z: 1.5,
        stage: "personal",
        color: techColors.personal,
        type: "square",
        t: 2.8,
    },
]

export const pathTransform = transform(
    [0, ...activities.map(a => a.t)],
    [{ x: 0, z: 0 }, ...activities.map(a => ({ x: a.x, z: a.z }))]
)

export const tOffset = {
    interviews: 0,
    bluetooth: 1,
    personal: 2,
}

const rand = randomUniform(-5, 5)

export const normalDots = new Array(40).fill("").map(() => ({
    x: rand(),
    z: rand(),
    color: techColors.normal,
    dx: randomUniform(-2, 2)(),
    dz: randomUniform(-2, 2)(),
}))

export const quarantineSquares = [
    { x: 3.5, z: 1.5 },
    { x: 2.5, z: 1.5 },
    { x: 1.5, z: 4.5 },
    { x: -2.5, z: 0.5 },
]
