import React, { useRef, useMemo, useEffect } from "react"
import {
    useWithinScrollRange,
    useVariableScrollRange,
    useOnChange,
} from "../../services"
import { colors } from "../../theme/colors"
import { useTransform } from "framer-motion"
import { Mesh, MeshPhongMaterial, Group } from "three"

import {
    Activity,
    activities,
    pathTransform,
    tOffset,
    normalDots,
    quarantineSquares,
} from "../../data/hwtwData"

const techColors = colors.tech

const pi = Math.PI

export const HWTW = () => {
    const group = useRef<Group>(null!)

    const scale = useVariableScrollRange(
        ["cube-top-end", "hwtw-start", "hwtw-end", "governance"],
        [0, 1, 1, 0]
    )

    useOnChange(scale, s => {
        group.current.scale.set(s, s, s)
    })

    const s = scale.get()

    return (
        <>
            <group ref={group} scale={[s, s, s]}>
                <HWTWScene />
            </group>
        </>
    )
}

export const HWTWScene = () => {
    const group = useRef<Group>(null!)

    const scale = useVariableScrollRange(
        ["personal-end", "aggregated-start", "analysis-end", "reopening-start"],
        [0.3, 0.1, 0.11, 0.4]
    )

    useEffect(
        () =>
            scale.onChange(s => {
                group.current.scale.set(s, s, s)
            }),
        [scale]
    )

    const s = scale.get()

    return (
        <group ref={group} scale={[s, s, s]}>
            <gridHelper args={[40, 40, 0x999999, 0x999999]} />

            <DetailedScene />

            <AggregatedScene />
        </group>
    )
}

export const AggregatedScene = () => {
    const visible = useWithinScrollRange("aggregated-start", "analysis-end", [
        0,
        100,
    ])

    return (
        <group visible={visible}>
            <Bondi />
            <SurryHills />
            <Newtown />
            <Chicago />
        </group>
    )
}

export const Bondi = () => {
    const aggT = useVariableScrollRange(
        ["aggregated-start", "analysis-start"],
        [0, 1]
    )

    const mesh = useRef<Mesh>(null!)
    const height = useTransform(
        aggT,
        [0, 0.1, 0.3, 0.6, 0.9, 1],
        [0, 0.01, 6, 6, 3, 0]
    )

    useOnChange(height, h => {
        mesh.current.scale.y = h
        mesh.current.position.y = h / 2
    })

    return (
        <mesh ref={mesh} scale={[10.5, 0, 10.5]} position-y={height.get() / 2}>
            <boxBufferGeometry attach="geometry" />
            <meshPhongMaterial
                attach="material"
                color={techColors.aggregated}
                flatShading
            />
        </mesh>
    )
}

export const SurryHills = () => {
    const aggT = useVariableScrollRange(
        ["aggregated-start", "analysis-start"],
        [0, 1]
    )

    const mesh = useRef<Mesh>(null!)
    const height = useTransform(
        aggT,
        [0, 0.1, 0.3, 0.6, 0.9, 1],
        [0, 0.01, 2, 2, 8, 0]
    )

    useOnChange(height, h => {
        mesh.current.scale.y = h
        mesh.current.position.y = h / 2
    })

    return (
        <mesh
            ref={mesh}
            scale={[5.5, height.get(), 8.5]}
            position={[-16, height.get() / 2, -8]}
        >
            <boxBufferGeometry attach="geometry" />
            <meshPhongMaterial
                attach="material"
                color={techColors.aggregated}
                flatShading
            />
        </mesh>
    )
}

export const Newtown = () => {
    const t = useVariableScrollRange(["analysis-start", "analysis-end"], [0, 1])

    const mesh = useRef<Mesh>(null!)
    const opacity = useTransform(
        t,
        [0, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
        [0, 0, 1, 0.1, 1, 0.1, 1]
    )

    useOnChange(opacity, o => {
        if (mesh.current) {
            const material = mesh.current.material as MeshPhongMaterial
            material.opacity = o
        }
    })

    return (
        <mesh ref={mesh} scale={[9.5, 0, 3]} position={[6, 0, 13]}>
            <boxBufferGeometry attach="geometry" />
            <meshPhongMaterial
                transparent={true}
                opacity={opacity.get()}
                attach="material"
                color={techColors.analysis}
                flatShading
            />
        </mesh>
    )
}

export const Chicago = () => {
    const t = useVariableScrollRange(["analysis-start", "analysis-end"], [0, 1])

    const mesh = useRef<Mesh>(null!)
    const opacity = useTransform(
        t,
        [0, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
        [0, 0, 1, 0.1, 1, 0.1, 1]
    )

    useOnChange(opacity, o => {
        if (mesh.current) {
            const material = mesh.current.material as MeshPhongMaterial
            material.opacity = o
        }
    })

    return (
        <mesh ref={mesh} scale={[5, 0, 16]} position={[11, 0, -5]}>
            <boxBufferGeometry attach="geometry" />
            <meshPhongMaterial
                transparent={true}
                opacity={opacity.get()}
                attach="material"
                color={techColors.analysis}
                flatShading
            />
        </mesh>
    )
}

export const DetailedScene = () => {
    return (
        <group>
            <PatientZero />

            {activities.map((a, i) =>
                a.type === "dot" ? (
                    <ActiveDot key={i} {...a} />
                ) : (
                    <ActiveSquare key={i} {...a} />
                )
            )}

            {normalDots.map((d, i) => (
                <InactiveDot key={i} {...d} />
            ))}

            {quarantineSquares.map((d, i) => (
                <QuarantineSquare key={i} {...d} />
            ))}

            <HealthyPlane />
        </group>
    )
}

const PatientZero = () => {
    const mesh = useRef<Mesh>(null!)

    const t = useVariableScrollRange(
        [
            "interviews-start",
            "interviews-end",
            "bluetooth-start",
            "bluetooth-end",
            "personal-start",
            "personal-end",
        ],
        [0, 1, 1, 2, 2, 3]
    )

    useOnChange(t, t => {
        if (mesh.current) {
            const { x, z } = pathTransform(t)
            mesh.current.position.x = x
            mesh.current.position.z = z
        }
    })

    const scale = useVariableScrollRange(
        ["analysis-end", "reopening-start"],
        [1, 0]
    )
    useOnChange(scale, s => {
        if (mesh.current) {
            mesh.current.scale.set(s, s, s)
        }
    })

    return (
        <mesh
            ref={mesh}
            scale={[scale.get(), scale.get(), scale.get()]}
            position={[0, 0.4, 0]}
        >
            <icosahedronBufferGeometry attach="geometry" args={[0.2, 1]} />
            <meshPhongMaterial
                attach="material"
                color={techColors.sick}
                flatShading
            />
        </mesh>
    )
}

const ActiveDot: React.FC<Activity> = props => {
    const mesh = useRef<Mesh>(null!)

    const xOffset = useMemo(() => Math.random() - 0.5, [])
    const yOffset = useMemo(() => Math.random() - 0.5, [])

    const t = useVariableScrollRange(
        [
            `${props.stage}-start`,
            `${props.stage}-end`,
            "analysis-end",
            "reopening-start",
        ],
        [0, 1, 1, 2]
    )

    const __t = props.t - (tOffset[props.stage] || 0)

    const color = useTransform(t, [0, __t], [techColors.normal, props.color])

    useOnChange(color, c => {
        if (mesh.current) {
            const material = mesh.current.material as MeshPhongMaterial
            material.color.set(c).convertSRGBToLinear()
        }
    })

    const scale = useTransform(
        t,
        [0, __t, __t + 0.2, 1, 2],
        [0.5, 0.5, 1, 1, 0]
    )
    useOnChange(scale, s => {
        if (mesh.current) {
            mesh.current.scale.set(s, s, s)
        }
    })

    return (
        <mesh
            ref={mesh}
            position={[props.x + xOffset, 0.2, props.z + yOffset]}
            scale={[scale.get(), scale.get(), scale.get()]}
        >
            <icosahedronBufferGeometry attach="geometry" args={[0.2, 1]} />
            <meshPhongMaterial
                attach="material"
                color={color.get()}
                flatShading
            />
        </mesh>
    )
}

const InactiveDot: React.FC<{
    x: number
    z: number
    color: string
    dx: number
    dz: number
}> = props => {
    const mesh = useRef<Mesh>(null!)

    const movement = useVariableScrollRange(
        ["movement-start", "governance-start"],
        [0, 2 * pi]
    )

    useOnChange(movement, m => {
        if (mesh.current) {
            mesh.current.position.x =
                props.x + Math.sin(m * props.dx) * props.dx
            mesh.current.position.z =
                props.z + Math.sin(m * props.dx) * props.dz
        }
    })

    return (
        <mesh ref={mesh} position={[props.x, 0.2, props.z]}>
            <icosahedronBufferGeometry attach="geometry" args={[0.1, 1]} />
            <meshPhongMaterial
                attach="material"
                color={techColors.normal}
                flatShading
            />
        </mesh>
    )
}

const QuarantineSquare: React.FC<{ x: number; z: number }> = props => {
    const mesh = useRef<Mesh>(null!)

    const t = useVariableScrollRange(
        ["reopening-start", "movement-start", "movement-end"],
        [0, 1, 2]
    )

    const scale = useTransform(t, [0, 1, 1.6, 1.8], [0, 1, 1, 0])

    useOnChange(scale, s => {
        if (mesh.current) {
            mesh.current.scale.set(s, s, s)
        }
    })

    return (
        <mesh
            ref={mesh}
            position={[props.x, 0, props.z]}
            scale={[scale.get(), scale.get(), scale.get()]}
        >
            <boxBufferGeometry attach="geometry" args={[1, 0.2, 1]} />
            <meshPhongMaterial attach="material" color={techColors.sick} />
        </mesh>
    )
}

const HealthyPlane = () => {
    const mesh = useRef<Mesh>(null!)

    const scale = useVariableScrollRange(
        ["movement-start", "movement-end"],
        [0, 50]
    )

    useOnChange(scale, s => {
        if (mesh.current) {
            mesh.current.scale.set(s, 0.1, s)
        }
    })

    return (
        <mesh ref={mesh} scale={[scale.get(), 0, scale.get()]} position-y={0}>
            <boxBufferGeometry attach="geometry" />
            <meshPhongMaterial
                attach="material"
                transparent={true}
                opacity={0.6}
                color={techColors.healthy}
                flatShading={true}
            />
        </mesh>
    )
}

const ActiveSquare: React.FC<Activity> = props => {
    const mesh = useRef<Mesh>(null!)

    const t = useVariableScrollRange(
        [
            `${props.stage}-start`,
            `${props.stage}-end`,
            "analysis-end",
            "reopening-start",
        ],
        [0, 1, 1, 2]
    )

    const __t = props.t - (tOffset[props.stage] || 0)

    const scale = useTransform(
        t,
        [0, __t, 0.8 > __t ? 0.8 : 1, 1, 2],
        [0, 0, 1, 1, 0]
    )

    useOnChange(scale, s => {
        if (mesh.current) {
            mesh.current.scale.set(s, s, s)
        }
    })

    return (
        <mesh
            ref={mesh}
            position={[props.x, 0, props.z]}
            scale={[scale.get(), scale.get(), scale.get()]}
        >
            <boxBufferGeometry attach="geometry" args={[1, 0.2, 1]} />
            <meshPhongMaterial attach="material" color={props.color} />
        </mesh>
    )
}
