import { useEffect } from "react"
import { useThree } from "react-three-fiber"
import { PerspectiveCamera } from "three"
import { labels } from "./TentacleLabels"
import { useVariableScrollRange } from "../../services"
import { piecewise, interpolate, interpolateObject } from "d3"
import { initialCameraPosition } from "./Camera"
import { useTransform } from "framer-motion"

const pi = Math.PI
const dist = 5

const krakenZoomOutPosition = {
    x: 0,
    y: dist * Math.sin(0.5 * pi),
    z: dist * Math.cos(0.5 * pi),
}

const labelCameraPositions = labels.map(({ camera }) => camera)
const labelCameraInterpolator = piecewise(
    interpolateObject,
    labelCameraPositions
)

export function useTentacleCamera() {
    const { camera: threeCamera } = useThree()
    const camera = threeCamera as PerspectiveCamera

    const spinT = useVariableScrollRange(
        ["kraken-spin-start", "kraken-spin-end"],
        [0, 1]
    )

    const krakenZoomIn = useTransform(spinT, [0, 0.2], [0, 1])
    useEffect(
        () =>
            krakenZoomIn.onChange(t => {
                const { x, y, z } = interpolate(
                    initialCameraPosition,
                    labelCameraPositions[0]
                )(t)
                camera.position.set(x, y, z)
                camera.lookAt(0, 0, 0)
            }),
        [camera, krakenZoomIn]
    )

    const tentacleSpin = useTransform(spinT, [0.2, 0.8], [0, 1])
    useEffect(
        () =>
            tentacleSpin.onChange(t => {
                const { x, y, z } = labelCameraInterpolator(t)
                camera.position.set(x, y, z)
                camera.lookAt(0, 0, 0)
            }),
        [camera, tentacleSpin]
    )

    const krakenZoomOut = useTransform(spinT, [0.8, 1], [0, 0.95])
    useEffect(
        () =>
            krakenZoomOut.onChange(t => {
                const { x, y, z } = interpolate(
                    labelCameraPositions[7],
                    krakenZoomOutPosition
                )(t)

                camera.position.set(x, y, z)
                camera.lookAt(0, 0, 0)
            }),
        [camera, krakenZoomOut]
    )
}
