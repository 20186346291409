/** @jsx jsx */
import { jsx } from "theme-ui"
import { Container, Flex, Box } from "@theme-ui/components"
import { FoundationBox } from "../FoundationBox"
import { Stack } from "raam"
import { useAfterScrollPoint, useSetScrollPoint } from "../../services"

export const Governance = () => {
    return (
        <StickyBox id="governance">
            <GovernanceInner />
        </StickyBox>
    )
}

const StickyBox: React.FC<{ id: string }> = ({ id, children }) => {
    const ref = useSetScrollPoint(id)

    return (
        <Box
            ref={ref}
            sx={{
                position: "sticky",
                width: "full",
                top: "0rem",
                zIndex: 777,
            }}
            pt="5rem"
            pb="m"
            bg="rgba(234,228,228, 0.8)"
        >
            {children}
        </Box>
    )
}

const GovernanceInner = () => {
    const governance = useAfterScrollPoint("governance", -100)
    const privacy = useAfterScrollPoint("gov-privacy")
    const consent = useAfterScrollPoint("gov-consent")
    const partnership = useAfterScrollPoint("gov-partnership")
    const transparency = useAfterScrollPoint("gov-transparency")
    const accountability = useAfterScrollPoint("gov-accountability")

    const privacyVariant = governance
        ? privacy
            ? "focus"
            : "unfocus"
        : "hidden"
    const consentVariant = governance
        ? consent
            ? "focus"
            : "unfocus"
        : "hidden"
    const partnershipVariant = governance
        ? partnership
            ? "focus"
            : "unfocus"
        : "hidden"
    const transparencyVariant = governance
        ? transparency
            ? "focus"
            : "unfocus"
        : "hidden"
    const accountabilityVariant = governance
        ? accountability
            ? "focus"
            : "unfocus"
        : "hidden"

    return (
        <Container py="xl">
            <Stack gap="m" alignItems="center">
                <Flex
                    sx={{
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                    }}
                >
                    <FoundationBox variant={privacyVariant}>
                        privacy
                    </FoundationBox>
                    <FoundationBox variant={consentVariant}>
                        consent
                    </FoundationBox>
                    <FoundationBox variant={partnershipVariant}>
                        partnership
                    </FoundationBox>
                    <FoundationBox variant={transparencyVariant}>
                        transparency
                    </FoundationBox>
                    <FoundationBox variant={accountabilityVariant}>
                        accountability
                    </FoundationBox>
                </Flex>
            </Stack>
        </Container>
    )
}
