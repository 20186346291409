/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Container } from "@theme-ui/components"
import { useSetScrollPoint } from "../../services"

export const InlineVis: React.FC<{
    id: string
}> = props => {
    const ref = useSetScrollPoint(props.id)

    return (
        <Container
            ref={ref}
            variant="inline-vis"
            sx={{
                position: "relative",
            }}
        >
            {props.children}
        </Container>
    )
}
