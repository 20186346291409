/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import { Canvas, useThree } from "react-three-fiber"
import { TechCube } from "./ThreeScenes/TechCube"
import { Lights } from "./ThreeScenes/Lights"
import { useWithinScrollRange, useOnChange } from "../services"
import { PerspectiveCamera, Vector3 } from "three"
import { useViewportScroll, useTransform } from "framer-motion"

export const SideThreeCanvas = () => {
    const showTechCube = useWithinScrollRange("cube-top-end", "governance")

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: ["35vh", null, "30vh"],
                right: 0,
                zIndex: 999,
                width: "30vw",
                height: ["30vh", null, "40vh"],
                pointerEvents: "none",
            }}
        >
            <Canvas pixelRatio={2} colorManagement>
                <Lights />

                {showTechCube ? <TechCube /> : null}

                <Camera />
            </Canvas>
        </Box>
    )
}

const pi = Math.PI

const cameraVec = new Vector3(0, 0, 0)

function Camera() {
    const { camera: threeCamera } = useThree()
    const camera = threeCamera as PerspectiveCamera

    const { scrollYProgress } = useViewportScroll()

    const ry = useTransform(scrollYProgress, [0, 1], [0, -2 * pi])

    useOnChange(ry, ry => {
        const x = Math.sin(ry)
        const y = 0.3
        const z = Math.cos(ry)

        cameraVec.set(x, y, z)
        cameraVec.normalize()
        cameraVec.multiplyScalar(5)

        camera.position.copy(cameraVec)
        camera.lookAt(0, 0, 0)
    })

    return null
}
