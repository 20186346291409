import React from "react"

import { StaticKraken } from "./StaticKraken"
import { Modelling } from "../StaticModelling"
import { StaticFoundations } from "./Foundations"
import { ReportImage } from "./ReportImage"

export const visualisations = {
    "kraken-overlay": <StaticKraken />,
    modelling: <Modelling />,
    "foundation-blocks": <StaticFoundations />,
    governance: <StaticFoundations />,
    "covid-form": <ReportImage />,
}
