import React, { useRef } from "react"
import {} from "react-three-fiber"
import { Group, Mesh } from "three"
import { useViewportScroll } from "framer-motion"
import { useOnChange, useVariableScrollRange } from "../../services"

import { colors } from "../../theme/colors"
const techColors = colors.tech

// const pi = Math.PI
// const rotationYTransform = transform([0, 1], [-Math.PI, Math.PI])

export const TechCube = () => {
    const group = useRef<Group>(null!)

    const scales: [string, number][] = [
        ["cube-top-zoom-out-start", 2],
        ["cube-top-end", 0],
        ["hwtw", 2],
        ["hwtw-end", 2],
        ["governance", 0],
        ["mech1-start", 0],
        ["mech1-end", 2],
        ["mechanisms-end", 2],
        ["outro-start", 0],
    ]

    const t = useVariableScrollRange(
        scales.map(x => x[0]),
        scales.map(x => x[1])
    )

    useOnChange(t, t => {
        if (group.current) {
            group.current.scale.set(t, t, t)
        }
    })

    const s = t.get()

    return (
        <group ref={group} scale={[s, s, s]}>
            <group position-y={-0.4}>
                <Personal />
                <Bluetooth />
                <Interviews />
                <HighLevel />
            </group>
        </group>
    )
}

function prep(keyframe) {
    return {
        keyframes: [
            `cube-${keyframe}`,
            `cube-${keyframe}`,
            "cube-top-end",
            "hwtw-start",
            `${keyframe}-start`,
            `${keyframe}-start`,
            `${keyframe}-end`,
            `${keyframe}-end`,
            `analysis-start`,
            `analysis-start`,
        ],
        scales: [0, 1, 1, 0.05, 0.05, 1, 1, 0.05, 0.05, 1],
        offsets: [-100, 100, 0, 0, -50, 200, -200, 50, 0, 200],
    }
}

const Interviews = () => {
    const mesh = useRef<Mesh>(null!)
    const color = techColors.interviews

    const { keyframes, scales, offsets } = prep("interviews")

    const scale = useVariableScrollRange(keyframes, scales, offsets)

    useOnChange(scale, s => {
        if (mesh.current) {
            mesh.current.scale.set(s, 1, s)
        }
    })

    return (
        <mesh ref={mesh} scale={[scale.get(), 1, scale.get()]}>
            <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
            <meshPhongMaterial attach="material" flatShading color={color} />
        </mesh>
    )
}

const Bluetooth = () => {
    const mesh = useRef<Mesh>(null!)
    const color = techColors.bluetooth

    const { keyframes, scales, offsets } = prep("bluetooth")

    const scale = useVariableScrollRange(keyframes, scales, offsets)

    useOnChange(scale, s => {
        if (mesh.current) {
            mesh.current.scale.set(s, 1, s)
        }
    })

    return (
        <mesh
            ref={mesh}
            position-y={0.11}
            scale={[scale.get(), 1, scale.get()]}
        >
            <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
            <meshPhongMaterial attach="material" flatShading color={color} />
        </mesh>
    )
}

const Personal = () => {
    const group = useRef<Group>(null!)
    const color = techColors.personal

    const { keyframes, scales, offsets } = prep("personal")

    const scale = useVariableScrollRange(keyframes, scales, offsets)

    useOnChange(scale, s => {
        if (group.current) {
            group.current.scale.set(s, 1, s)
        }
    })

    return (
        <group ref={group} scale={[scale.get(), 1, scale.get()]}>
            <mesh position-y={0.22}>
                <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
                <meshPhongMaterial
                    attach="material"
                    flatShading
                    color={color}
                />
            </mesh>
            <mesh position-y={0.33}>
                <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
                <meshPhongMaterial
                    attach="material"
                    flatShading
                    color={color}
                />
            </mesh>
            <mesh position-y={0.44}>
                <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
                <meshPhongMaterial
                    attach="material"
                    flatShading
                    color={color}
                />
            </mesh>
        </group>
    )
}

const HighLevel = () => {
    const color = techColors.aggregated
    const group = useRef<Group>(null!)

    const { keyframes, scales, offsets } = prep("aggregated")

    const scale = useVariableScrollRange(keyframes, scales, offsets)

    useOnChange(scale, s => {
        if (group.current) {
            group.current.scale.set(s, 1, s)
        }
    })

    return (
        <group ref={group} scale={[scale.get(), 1, scale.get()]}>
            <mesh position-y={0.55}>
                <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
                <meshPhongMaterial
                    attach="material"
                    flatShading
                    color={color}
                />
            </mesh>
            <mesh position-y={0.66}>
                <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
                <meshPhongMaterial
                    attach="material"
                    flatShading
                    color={color}
                />
            </mesh>
            <mesh position-y={0.77}>
                <boxBufferGeometry attach="geometry" args={[1, 0.1, 1]} />
                <meshPhongMaterial
                    attach="material"
                    flatShading
                    color={color}
                />
            </mesh>
        </group>
    )
}
