/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Container, Flex, Text } from "@theme-ui/components"
import { Title } from "./Title"
import { StaticStoryRenderer } from "./StaticStoryRenderer"
import { MdError } from "react-icons/md"
import { useStaticQuery, graphql } from "gatsby"
import { Stack } from "raam"

export const StaticStory: React.FC = () => {
    const data = useStaticQuery(graphql`
        query StaticContactTracingStory {
            sanityStory(title: { eq: "TO ZERO & BEYOND" }) {
                _rawContent
            }
        }
    `)

    const blocks = data.sanityStory._rawContent.filter(block =>
        ["transitionCallout", "block", "visualisation"].includes(block._type)
    )

    return (
        <Stack>
            <Title />

            <Stack gap="l" id="static-story">
                <Container p={["s", "m", "l"]}>
                    <Box
                        py={["m", "l"]}
                        px="m"
                        bg="sd.black"
                        color="sd.beige"
                        sx={{ boxShadow: ".5rem .5rem 0px gray" }}
                    >
                        <Flex sx={{ alignItems: "center" }}>
                            <MdError
                                sx={{
                                    color: "sd.orange",
                                    width: ["l", "xl"],
                                    height: ["l", "xl"],
                                    mr: ["m", "l"],
                                    flexShrink: 0,
                                }}
                            />
                            <Text variant="body" sx={{ fontSize: ["m", "l"] }}>
                                You are currently viewing the static version of
                                this whitepaper. For the full interactive
                                experience please visit this whitepaper on a
                                modern web browser such as Chrome, Firefox or
                                Safari.
                            </Text>
                        </Flex>
                    </Box>
                </Container>

                <StaticStoryRenderer blocks={blocks} />
            </Stack>
        </Stack>
    )
}
