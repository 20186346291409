/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box, Text, Grid } from "@theme-ui/components"
import { useSetScrollPoint } from "../services"
import { Stack } from "./Stack"

export const ScrollPoint = ({ id, name }: { id: string; name: string }) => {
    const ref = useSetScrollPoint(id)

    const dev = process.env.NODE_ENV === "development"

    return (
        <Box ref={ref}>
            {dev && (
                <Grid columns="1rem auto 1fr" gap="m">
                    <Box
                        sx={{ height: "1px" }}
                        opacity={0.25}
                        bg="sd.black"
                    ></Box>

                    <Text
                        variant="headline"
                        color="sd.black"
                        sx={{ fontSize: "xs", opacity: 0.25 }}
                    >
                        {id}
                    </Text>
                    <Box
                        sx={{ height: "1px" }}
                        opacity={0.25}
                        bg="sd.black"
                    ></Box>
                </Grid>
            )}
        </Box>
    )
}
